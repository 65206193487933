<!--<nav id="sidebar" [ngClass]="{active: active}">-->
<!--  <div class="sidebar-header">-->
<!--    <img src="/assets/images/pwc-logo-white.svg" class="logo">-->
<!--    <button type="button" id="sidebarCollapse" class="btn btn-info" style="display: none">-->
<!--      <i class="fas fa-align-left"></i>-->
<!--    </button>-->
<!--  </div>-->
<div style="display: flex;flex-direction: column;min-height: calc(100vh - 80px);position:relative">
  <ul class="list-unstyled components" style="flex:1">
    <ng-container *canAccess="configAuthorities">
<!--      <li routerLinkActive="mainActive" class="pointer" *ngIf="activeMenu === NavigationMenu.MAIN">-->
<!--        <a routerLink="/users" class="d-flex align-items-center">-->
<!--          <img src="/assets/images/icons/menu/configurazione.svg">-->
<!--          <span *ngIf="showDesc" translate>menu.system-configuration.label</span>-->
<!--        </a>-->
<!--      </li>-->
      <li routerLinkActive="mainActive" class="pointer">
        <a routerLink="/users" (click)="menuCommunicoCollaped = !menuCommunicoCollaped"
           class="d-flex align-items-center"
           [attr.aria-expanded]="!menuCommunicoCollaped" aria-controls="collapseCommunico">
          <img [src]="baseHref + '/assets/images/icons/menu/configurazione.svg'">
          <span *ngIf="showDesc" translate>menu.system-configuration.label</span>
        </a>
        <ul class="list-unstyled list-2liv" [ngbCollapse]="menuCommunicoCollaped" id="collapseCommunico">
          <li>
            <a routerLink="/" (click)="menuCommunicoCollaped = true" class="d-flex align-items-center">
              <img [src]="baseHref + '/assets/images/icons/home.svg'">
              <span *ngIf="showDesc" translate>menu.submenu</span>
            </a>
          </li>
          <hr>
        </ul>
      </li>
    </ng-container>
    <ng-container>
      <li routerLinkActive="active" class="pointer" *canAccess="requiredAuthorities.users">
        <a routerLink="/users" class="d-flex align-items-center">
          <img [src]="baseHref + '/assets/images/icons/menu/utenti.svg'">
          <span *ngIf="showDesc" translate>menu.system-configuration.submenu.user-registry</span>
        </a>
      </li>
      <li routerLinkActive="active" class="pointer" *canAccess="requiredAuthorities.company">
        <a routerLink="/companies" class="d-flex align-items-center">
          <img [src]="baseHref + '/assets/images/icons/menu/societa.svg'">
          <span *ngIf="showDesc" translate>menu.system-configuration.submenu.society-registry</span>
        </a>
      </li>
      <li routerLinkActive="active" class="pointer" *canAccess="requiredAuthorities.teams">
        <a routerLink="/teams" class="d-flex align-items-center">
          <img [src]="baseHref + '/assets/images/icons/menu/societa.svg'">
          <span *ngIf="showDesc" translate>menu.system-configuration.submenu.team-registry</span>
        </a>
      </li>
      <li routerLinkActive="active" class="pointer" *canAccess="requiredAuthorities.generalSettings">
        <a routerLink="/general-settings" class="d-flex align-items-center">
          <img [src]="baseHref + '/assets/images/icons/menu/configurazione.svg'">
          <span *ngIf="showDesc" translate>menu.system-configuration.submenu.general-settings</span>
        </a>
      </li>
    </ng-container>
<!--    <ng-container *ngIf="activeMenu === NavigationMenu.MAIN">-->
<!--      <li routerLinkActive="active" class="pointer" style="background-color: #e03120;" *ngIf="showElectronicInvoice">-->
<!--        <a class="d-flex align-items-center">-->
<!--          <img src="/assets/images/icons/menu/fatturazione-elettronica.svg">-->
<!--          <span *ngIf="showDesc" translate>menu.electronic-invoice.label</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li routerLinkActive="active" class="pointer" style="background-color: #d8560a;" *ngIf="show2086">-->
<!--        <a [routerLink]="" (click)="goTo2086()" class="d-flex align-items-center">-->
<!--          <img src="/assets/images/icons/menu/2086.svg">-->
<!--          <span *ngIf="showDesc" translate>menu.2086-digital-solution.label</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li routerLinkActive="active" class="pointer" style="background-color: #e78d15;" *ngIf="showExpenseReport">-->
<!--        <a class="d-flex align-items-center">-->
<!--          <img src="/assets/images/icons/menu/nota-spese.svg">-->
<!--          <span *ngIf="showDesc" translate>menu.expense-report.label</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li routerLinkActive="active" class="pointer" style="background-color: #f3bd27;" *ngIf="showGovernmentIncentives">-->
<!--        <a class="d-flex align-items-center">-->
<!--          <img src="/assets/images/icons/menu/incentivi.svg">-->
<!--          <span *ngIf="showDesc" translate>menu.government-incentives.label</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li routerLinkActive="active" class="pointer" *canAccess="requiredAuthorities.dms">-->
<!--        <a [href]="urlDocumentale" class="d-flex align-items-center">-->
<!--          <img src="/assets/images/icons/menu/documentale.svg">-->
<!--          <span *ngIf="showDesc" translate>menu.document-management.label</span>-->
<!--        </a>-->
<!--      </li>-->

<!--      <li class="pointer" *canAccess="requiredAuthorities.ecobonus">-->
<!--        <a [href]="urlEcobonus" class="d-flex align-items-center">-->
<!--          <img src="/assets/images/icons/menu/ecobonus.svg">-->
<!--          <span *ngIf="showDesc" translate>menu.ecobonus.label</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </ng-container>-->
  </ul>
</div>
<!--</nav>-->
