import {Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from 'src/app/directives/sortable.directive';
import {Team} from '../models/team';
import {TeamsService} from 'src/app/services/teams.service';
import {TranslateService} from '@ngx-translate/core';
import {Authorities} from 'src/app/enums/security/authorities.enum';
import {isNullOrUndefined} from 'util';
import {mapTranslateMessages, tranlastionKeys} from 'src/app/helpers/generic-utils';
import {TeamResource} from '../models/api/team-resource';
import {CompanyService} from 'src/app/services/company.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss']
})
export class TeamsListComponent implements OnInit {

  public teams: Team[] = [];
  filter: TeamResource = new TeamResource();
  filteredList$: Observable<Team[]>;
  total$: Observable<number>;
  total: number;
  selectAll: boolean = false;
  selectedList: Team[] = [];
  filteredList: Team[] = [];
  selectedCount: number = 0;
  companies = [];

  readonly baseHref = environment.baseHref;

  messages: any;

  requiredAuthorities = {
    group: {
      read: Authorities.ROLE_COMPANY_READ,
      create: Authorities.ROLE_COMPANY_CREATE,
      update: Authorities.ROLE_COMPANY_UPDATE,
      delete: Authorities.ROLE_COMPANY_DELETE,
    }
  };

  @Output() showLoading = new EventEmitter<boolean>();
  @Output() openDetail = new EventEmitter<object>();
  @ViewChild('alertModalList', {static: true}) alertModal: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  constructor(
    private teamService: TeamsService,
    private companyService: CompanyService,
    private translateService: TranslateService) {

    this.initTranslations();
    this.translateService.onLangChange.subscribe(event => {
      this.initTranslations();
    });

  }

  initTranslations() {
    if (isNullOrUndefined(this.translateService)) {
      return;
    }

    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
      // console.log('messages', this.messages);
    });
  }


  resetFilters() {
    this.filter = new Team();
  }

  ngOnInit(): void {
    this.listTeams();
    this.companyService.getAll().subscribe(data => this.companies = data);
    this.filter.size = 10;
  }


  listTeams() {
    let filter: TeamResource = this.getFilters();
    this.teamService.search(filter).subscribe(data => {
      if (this.teamService.sortColumn != null) {
        this.teams = this.teamService.sort(data, this.teamService.sortColumn, this.teamService.sortDirection);
      } else {
        this.teams = data;
      }
    });

  }

  deleteMassiveGroups() {
    if (confirm(this.messages.deleteSelectedConfirm)) {
      this.teamService.deleteByIds(this.selectedList.map(u => u.id)).subscribe(res => {
        this.openAlert(0, 'success', this.messages.deleteSuccess);
      }, (err) => {
        // console.log(err);
        this.openAlert(0, 'error', this.messages.errorGeneric);
      });
    }
  }

  export(selected: boolean = false) {
    let fullList = [];
    if (selected) {
      fullList = this.selectedList;
    } else {
      fullList = this.teams;
    }
    // console.log('export list', fullList);
    const list = fullList.map((i: Team) => {
      return {
        id: i.id,
        teamName: i.teamName,
      };
    });
    this.teamService.exportAsExcel(list);
  }

  selectRows(id: number, selected: boolean) {
    // console.log('selected', selected);
    this.teams.forEach(i => {
      i.selected = (i.id === id) ? selected : i.selected;
    });
    this.selectedList = this.teams.filter(e => e.selected);
    this.selectedCount = this.selectedList.length;
    this.selectAll = false;
  }

  selectAllRows() {
    this.teams.forEach(i => {
      i.selected = true;
    });
    this.selectedList = this.teams;
    this.selectedCount = this.teams.length;
    this.selectAll = true;
  }

  selectVisibleRows(selected: boolean) {
    this.teams.forEach(i => {
      i.selected = selected;
    });
    if (selected) {
      this.selectedList = this.teams.filter(e => e.selected);
    } else {
      this.selectedList = [];
    }
    this.selectedCount = this.selectedList.length;
    this.selectAll = selected;
  }

  pageChange() {
    this.selectAll = false;
    this.selectVisibleRows(false);
  }

  deleteTeam(id: any) {
    if (confirm(this.messages.deleteConfirm)) {
      console.log('delete group ' + id);
      this.teamService.delete(id).subscribe(data => {
        this.openAlert(0, 'success', this.messages.savingSuccess);
        this.listTeams();
      }, (err) => {
        // console.log(err);
        this.openAlert(0, 'error', this.messages.errorSaving);
        this.listTeams();
      });
    }
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.teamService.sortColumn = column;
    this.teamService.sortDirection = direction;

    this.listTeams();

  }

  getFilters(): TeamResource {
    let filter: TeamResource = new TeamResource();
    delete filter.selected;
    delete filter.society;
    if (this.filter.id != null && this.filter.id != 0) {
      filter.id = this.filter.id;
    }
    if (this.filter.teamName != null && this.filter.teamName.trim() != '') {
      filter.teamName = this.filter.teamName;
    }
    if (this.filter.societyId != null) {
      filter.societyId = this.filter.societyId;
    }

    return filter;
  }

  onOpenDetail(teamId: number, permission: string) {
    this.openDetail.emit({teamId, permission});
  }

  callbackApprovation(type: string) {
    this.listTeams();
  }
}
