import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertComponent} from '../../modals/alert/alert.component';
import {mapTranslateMessages, tranlastionKeys} from '../../../helpers/generic-utils';
import {TranslateService} from '@ngx-translate/core';
import {MustMatch} from '../../../helpers/must-match.validator';
import {AuthenticationService, CryptoService, PasswordStrengthService} from '@pwc/security';
import {User} from '@pwc/common';
import {UserService} from '../../../services/user.service';
import {piInfo, PwcIconsLibrary} from '@pwc/icons';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  user: User = new User();
  loading: boolean = false;
  submitted: boolean = false;
  messages: any;

  @ViewChild('alertModal', {static: true}) alertModal: AlertComponent;

  constructor(private userService: UserService,
              private authenticationService: AuthenticationService,
              private translateService: TranslateService,
              private formBuilder: FormBuilder,
              private cryptoService: CryptoService,
              private passwordStrengthService: PasswordStrengthService,
              private library: PwcIconsLibrary) {
    this.library.registerIcons([
      piInfo
    ]);
  }

  ngOnInit(): void {
    this.loading = true;
    this.user = new User();
    this.initTranslations();
    this.initValidation();

    this.authenticationService.dataUser()
      .subscribe((user: User) => {
        this.user = {...user};
        this.initValidation();
        this.loading = false;
      }, (error) => {
        // console.error(error);
        this.openAlert(0, 'error', this.messages.errorLoading);
        this.loading = false;
      });
  }

  private initTranslations(): void {
    if (this.translateService == null) {
      return;
    }

    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
      // console.log('messages', this.messages);
    });
  }

  private initValidation(): void {
    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() {
    return this.form.controls;
  }

  save(): void {
    this.submitted = true;
    if (this.form.invalid) {
      console.warn('Form invalid');
      return;
    }

    const password = this.f.password.value;
    if (password !== '') {
      //  Check password strength.
      const strength = this.passwordStrengthService.checkPassword(password);
      if (strength != null && strength.score < 3) {
        this.openAlert(0, 'error', this.translateService.instant('generic.message.validation.weak-password'));
        return;
      }
    }

    this.loading = true;
    const request = {
      oldPassword: this.cryptoService.encryptToString(this.f.oldPassword.value),
      password: this.cryptoService.encryptToString(password)
    }
    this.user.password = this.cryptoService.encryptToString(password);
    if (password === '') {
      return;
    }

    this.userService.changePassword(request)
      .subscribe((res: User) => {
        this.user = {...res};
        this.openAlert(0, 'success', this.messages.savingSuccess);
        this.loading = false;
      }, err => {
        // console.error(err);
        if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
          const msg = this.translateService.instant('validation.password.history');
          this.openAlert(0, 'error', msg);
        } else if (err.status === 422 && err.error.type === 'invalid') {
          const msg = this.translateService.instant('validation.password.invalid');
          this.openAlert(0, 'error', msg);
        } else {
          this.openAlert(0, 'error', this.messages.errorSaving);
        }
        this.loading = false;
      });
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  callbackApprovation(type: string) {
    if (type === 'success') {
      delete this.user.password;
      this.authenticationService.logout();
      location.href = '/login';
    }
  }
}
