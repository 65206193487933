export abstract class ApiResource {
  id: any;
  version?: number;
  flagValid?: boolean;
  flagActive?: boolean;
  flagDeleted?: boolean;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
}
