import {Component, OnInit, ViewChild} from '@angular/core';
import {ListHelper} from '../../../helpers/list-helper';
import {Authorities} from '../../../enums/security/authorities.enum';
import {Company} from '../../../models/company.model';
import {User} from '../../../models/user.model';
import {UserService} from '../../../services/user.service';
import {GeneralSettingValue} from '../../../models/general-setting-value.model';
import {GeneralSettingValueListService} from '../../../services/general-setting-value-list.service';
import {CompanyService} from '../../../services/company.service';
import {DecimalPipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {GeneralSettingTypeEnum} from '../../../enums/GeneralSettingTypeEnum';
import {isNullOrUndefined} from 'util';
import {mapTranslateMessages, tranlastionKeys} from '../../../helpers/generic-utils';
import {AuthorizationService} from '@pwc/security';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-settings-list',
  templateUrl: './general-settings-list.component.html',
  styleUrls: ['./general-settings-list.component.scss'],
    providers: [GeneralSettingValueListService, DecimalPipe]
})
export class GeneralSettingsListComponent extends ListHelper<GeneralSettingValue> implements OnInit {

    requiredAuthorities = {
        read: Authorities.ROLE_GENERAL_SETTING_VALUE_READ,
        create: Authorities.ROLE_GENERAL_SETTING_VALUE_CREATE,
        update: Authorities.ROLE_GENERAL_SETTING_VALUE_UPDATE,
        delete: Authorities.ROLE_GENERAL_SETTING_VALUE_DELETE,
        admin: {
            read: Authorities.ROLE_PARENT_COMPANY_READ,
            create: Authorities.ROLE_PARENT_COMPANY_CREATE,
            update: Authorities.ROLE_PARENT_COMPANY_UPDATE,
            delete: Authorities.ROLE_PARENT_COMPANY_DELETE,
        }
    };

    companyList: Company[] = [];
    userList: User[] = [];
    isAdmin: boolean = false;

    GeneralSettingTypeEnum = GeneralSettingTypeEnum;
    @ViewChild('alertModalList', { static: true }) alertModal: any;

    messages: any;

    readonly baseHref = environment.baseHref;

    constructor(public service: GeneralSettingValueListService,
                private userService: UserService,
                private serviceCompany: CompanyService,
                private translateService: TranslateService,
                private auth: AuthorizationService) {
        super(service);
        this.initTranslations();
    }

    initTranslations() {
        if (isNullOrUndefined(this.translateService))
            return;

        this.translateService.get(tranlastionKeys).subscribe(translated => {
            this.messages = mapTranslateMessages(translated);
            // console.log('messages', this.messages);
        });
    }

    onOpenDetail(id: number, permission: string) {
        this.openDetail.emit({id, permission});
    }

    selectVisibleRows(selected: boolean) {
        super.selectVisibleRows(selected);
        this.checkEnableDelete();
    }

    selectAllRows() {
        super.selectAllRows();
        this.checkEnableDelete();
    }

    selectRows(id: number, selected: boolean) {
        super.selectRows(id, selected)
        this.checkEnableDelete();
    }

    ngOnInit() {
        this.auth.checkAuthorities(this.requiredAuthorities.admin.create)
            .subscribe((authorized: boolean) => {
                this.userService.loadService(authorized);
                this.userService.filteredList$.subscribe( list => this.userList = list);

                this.serviceCompany.loadService();
                this.serviceCompany.filteredList$.subscribe( list => this.companyList = list);

                this.service.loadService(authorized);
                this.isAdmin = authorized;

                // this.service.getGeneralSettingKey(authorized ? null : false).subscribe(settings => {
                //     console.log('settings', settings);
                //     if (!authorized) {
                //         this.generalSettingList = settings.filter(s => !s.adminProfiling);
                //     } else {
                //         this.generalSettingList = settings;
                //     }
                //
                //     this.checkVisibilityProfiling();
                // });
            });



        // this.service.loadService();

        // this.serviceCompany.getAll().subscribe(list => {
        //     this.companyList = list;
        //     console.log('this.companyList', this.companyList);
        // });
        // this.userService.getAll().subscribe(list => {
        //     this.userList = list;
        //     console.log('this.userList', this.companyList);
        // });
        this.selectAll = false;

        this.service.loading$.subscribe(show => {
            this.onShowLoading(show);
        });
        this.total$.subscribe(total => {
            this.total = total;
        });
        this.filteredList$.subscribe(list => {
            this.filteredList = list;
            // console.log('this.filteredList', this.filteredList.length);
        });
    }

    pageChange() {
        this.selectAll = false;
        this.selectVisibleRows(false);
    }

    deleteElement(id: any) {
        if (confirm(this.messages.deleteConfirm)) {
            this.service.deleteElement(id).subscribe(res => {
                this.openAlert(0, 'success', this.messages.deleteSuccess);
            }, (err) => {
                // console.log(err);
                this.openAlert(0, 'error', this.messages.errorGeneric);
            });
        }
    }

    deleteMassiveElements() {
        if (confirm(this.messages.deleteSelectedConfirm)) {
            this.service.deleteMassiveElements(this.selectedList.map(u => u.id)).subscribe(res => {
                this.openAlert(0, 'success', this.messages.deleteSuccess);
            }, (err) => {
                // console.log(err);
                this.openAlert(0, 'error', this.messages.errorGeneric);
            });
        }
    }

    checkEnableDelete() {
    }

    openAlert(id: any, type: string, message: string) {
        this.alertModal.openModalAlert(id, type, message);
    }

    callbackApprovation(type: string) {
        if (type === 'success') {
            this.service.loadService(this.isAdmin);
        }
    }

    authoritiesForAction(action: string): string | string[] {
        return this.requiredAuthorities[action];
    }

    export(selected: boolean = false) {
        let fullList = [];
        if (selected) {
            fullList = this.selectedList;
        } else {
            fullList = this.filteredList;
        }
        // console.log('export list', fullList);
        const list = fullList.map(i => {
            return {
                id: i.id,
                key: i.setting.description,
                company: !isNullOrUndefined(i.society) ? i.society.name : '',
                user:  !isNullOrUndefined(i.user) ? i.user.username : '',
                value: i.value
            };
        });
        super.exportAsExcel(list);
    }

    getUserNaming(user: User) {
        return user.lastName + ' ' + user.firstName;
    }
}
