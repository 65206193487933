<div class="row login-page" [ngStyle]="loginBackground">
  <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 ml-auto mr-auto login-col">
    <section class="login-box">
      <div class="row">
        <div class="col align-self-center">
          <div class="card login-card">
            <div class="card-title">
              <div class="col lang-box" *ngIf="false">
                <select class="custom-select" id="gender2" style="float: right">
                  <option selected value="it">IT</option>
                  <option value="en">EN</option>
                </select>
              </div>
            </div>
            <div class="card-body">
              <div *ngIf="ecobonusFlag">
                <div class="col welcome-box mt-4 lead text-center">
                  Piattaforma
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/login-ecobonus.png'"/>
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-login.png'"/>
                </div>
              </div>
              <div *ngIf="bandiIncentiviFlag">
                <div class="col welcome-box mt-4 lead text-center">
                  Piattaforma
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-bandi-e-incentivi.png'"/>
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-login.png'"/>
                </div>
              </div>
              <div *ngIf="!ecobonusFlag && !bandiIncentiviFlag" class="col image-box mt-2">
                <img class="image-login" [src]="baseHref + '/assets/images/digital-suite-tls.png'"/>
              </div>
              <div class="col welcome-box mb-4">
                <h3>Benvenuto</h3>
                <span class="subtitle">Inserisci le credenziali ricevute</span>
              </div>
              <div class="col form-box">
                <form *ngIf="!otpRequired" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" maxlength="255" formControlName="username" class="form-control"
                           id="username" name="username"
                           [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                           id="password" name="password"
                           [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                  </div>
                  <div class="form-check mb-4">
                    <!--                    <input type="checkbox" class="form-check-input" id="exampleCheck1">-->
                    <!--                    <label class="form-check-label" for="exampleCheck1">Resta collegato</label>-->
                    <div class="box-link-lost-password">
                      <a routerLink="/forgot-password">Hai dimenticato la password?</a>
                    </div>
                  </div>
                  <div class="form-group">
                    <br>
                    <button [disabled]="loading" class="btn btn-primary login-btn">Accedi</button>
                    <div class="text-center mt-3" *ngIf="ecobonusFlag && faq">
                      <a [href]="urlEcobonus + '/login-faq'" target="_blank">Hai bisogno di aiuto?</a>
                    </div>
                    <img *ngIf="loading" class="pl-2"
                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                  </div>
                  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                </form>

                <form *ngIf="otpRequired" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
                  <div class="form-group">
                    <label for="code">Inserisci il codice di accesso ricevuto via e-mail</label>
                    <input type="text" maxlength="255" formControlName="code" id="code" name="code" class="form-control"
                           [ngClass]="{ 'is-invalid': submitted && o.code.errors }"/>
                    <div *ngIf="submitted && o.code.errors" class="invalid-feedback">
                      <div *ngIf="o.code.errors.required">Code is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <br>
                    <button [disabled]="loading" class="btn btn-primary login-btn">Accedi</button>
                    <div class="text-center mt-3" *ngIf="ecobonusFlag && faq">
                      <a [href]="urlEcobonus + '/login-faq'" target="_blank">Hai bisogno di aiuto?</a>
                    </div>
                    <img *ngIf="loading" class="pl-2"
                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                  </div>
                  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                </form>
              </div>
              <!--              <div class="col welcome-box" *ngIf="false">-->
              <!--                <hr>-->
              <!--                <span>Non sei ancora registrato? <a href="#">REGISTRATI</a></span>-->

              <!--              </div>-->
              <div class="col welcome-box">
                <br>
                <img alt="PwC TLS Avvocati e commercialisti" class="logo-footer ml-auto"
                     [src]="baseHref + '/assets/images/logo-footer.svg'">
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</div>
