import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TraceabilityService} from '../../../services/traceability.service';
import {first} from 'rxjs/operators';
import {MustMatch} from '../../../helpers/must-match.validator';
import {AuthenticationService, CryptoService} from '@pwc/security';
import {TranslateService} from '@ngx-translate/core';
import {piInfo, PwcIconsLibrary} from '@pwc/icons';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  token: string = '';
  showSuccess: boolean = false;
  readonly ecobonusFlag = environment.ecobonusFlag;
  readonly bandiIncentiviFlag = environment.bandiIncentiviFlag;
  readonly baseHref = environment.baseHref;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private traceService: TraceabilityService,
    private cryptoService: CryptoService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private library: PwcIconsLibrary
  ) {
    this.library.registerIcons([
      piInfo
    ]);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.route.params.subscribe(params => {
      this.token = params.token;
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const password = this.cryptoService.encryptToString(this.loginForm.get('password').value);
    this.authenticationService.resetPassword(this.token, password)
      .pipe(first())
      .subscribe(
        res => {
          console.log('reser', res);
          this.loading = false;
          this.showSuccess = true;
          // const trace = new Traceability();
          // trace.activity = 'Log-in';
          // trace.process = TraceabilityProcessEnum.USER_ACCESS;
          // this.router.navigate([this.returnUrl]).then(() => {
          //     this.traceService.addTraceability(trace).subscribe(res => {
          //         console.log('trace', res);
          //     }, error => {
          //         console.error(error);
          //     });
          // });
        },
        error => {
          console.log(error);
          let msg = 'Errore nell\'aggiornamento della password';
          if (error.status === 422 && error.error?.type === 'unique_constraint_violated') {
            msg = this.translateService.instant('validation.password.history');
          } else if (error.status === 422 && error.error?.type === 'invalid') {
            msg = this.translateService.instant('validation.password.invalid');
          }

          this.error = msg;
          this.loading = false;
        });
  }
}
