import {SelectableModel} from './selectable-model';
import {YesNoEnum} from "../enums/yes-no.enum";

export class Company extends SelectableModel {
    id: number;
    name: string;
    vatNumber: string;
    fiscalCode: string;
    code?: string;
    countryTaxResidenceId: string;
    address: string;
    countryEstablishmentId: string;
    typeBusinessAccordingId: string;
    mainBusinessSectorId: string;
    membershipInGroup: boolean;
    nameParentCompany: string;
    countryParentCompanyId: string;
    parentCompanyId: number;
    parentCompany: any;
    isParentCompany: YesNoEnum;

    constructor(id: number, name: string, vatNumber: string, fiscalCode: string, country: string, address: string, birthCountry: string, type: string, industry: string, group: boolean, isParentCompany: YesNoEnum, parentCompanyId: number = null) {
        super();
        this.id = id;
        this.name = name;
        this.vatNumber = vatNumber;
        this.fiscalCode = fiscalCode;
        this.countryTaxResidenceId = country;
        this.address = address;
        this.countryEstablishmentId = birthCountry;
        this.typeBusinessAccordingId = type;
        this.mainBusinessSectorId = industry;
        this.membershipInGroup = group;
        this.parentCompanyId = parentCompanyId;
        this.isParentCompany = isParentCompany;
    }
}
