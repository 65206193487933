<widget [contentRef]="widgetContent" [footerRef]="widgetFooter" [noPadding]="true" variant="primary"></widget>

<ng-template #widgetContent>
  <div class="p-4">
    <p class="text-center">
      Communico ha tutte le soluzioni
      <br>
      per agevolarti nella tua attivit&agrave;!
    </p>

    <div class="pt-4">
      <ngb-carousel [showNavigationIndicators]="false">
        <ng-template ngbSlide>
          <div class="text-center">
            <img [src]="baseHref + '/assets/images/icons/menu/fatturazione-elettronica.svg'" class="icon-large" alt="">
            <span>Fatturazione Elettronica</span>
          </div>
        </ng-template>

        <ng-template ngbSlide>
          <div class="text-center">
            <img [src]="baseHref + '/assets/images/icons/menu/2086.svg'" class="icon-large" alt="">
            <span>2086 Digital Solution</span>
          </div>
        </ng-template>

        <ng-template ngbSlide>
          <div class="text-center">
            <img [src]="baseHref + '/assets/images/icons/menu/nota-spese.svg'" class="icon-large" alt="">
            <span>Gestione nota spese</span>
          </div>
        </ng-template>

        <ng-template ngbSlide>
          <div class="text-center">
            <img [src]="baseHref + '/assets/images/icons/menu/incentivi.svg'" class="icon-large" alt="">
            <span>Incentivi governativi</span>
          </div>
        </ng-template>

        <ng-template ngbSlide>
          <div class="text-center">
            <img [src]="baseHref + '/assets/images/icons/menu/documentale.svg'" class="icon-large" alt="">
            <span>Documentale</span>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</ng-template>

<ng-template #widgetFooter>
  <div class="text-center">
    <a href="#" class="contact-link">
      <img [src]="baseHref + '/assets/images/icons/communico-icon.svg'" class="icon" alt="">
      <strong>
        Contattaci per ricevere informazioni
      </strong>
    </a>
  </div>
</ng-template>
