import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiResourceConverter} from '../../contracts';
import {ApiResource, BaseModel} from '../../models';
import {HttpUtils} from '../../utils/http-utils';
import {StringUtils} from '../../utils/string-utils';

@Injectable({
  providedIn: 'root'
})
export abstract class HttpService<R extends ApiResource, M extends BaseModel> implements ApiResourceConverter<R, M> {
  protected abstract baseUrl: string;
  protected abstract endpoint: string;

  constructor(protected http: HttpClient) {
  }

  protected get<T>(url: string = '', options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.http.get<T>(this.buildEndpointUrl(`${url}`), options);
  }

  protected getBlob<Blob>(url: string = '', param: HttpParams, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>> {
    let params = '';
    if (param != null) {
      params = param.toString();
    }
    return this.http.get<Blob>(this.buildEndpointUrl(`${url}?${params}`), {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  protected post<T>(url: string = '', data: any): Observable<T> {
    return this.http.post<T>(this.buildEndpointUrl(url), data);
  }

  protected put<T>(url: string = '', data: object): Observable<T> {
    return this.http.put<T>(this.buildEndpointUrl(url), data);
  }

  public search(search?: Partial<R>): Observable<M[]> {
    const params = HttpUtils.toHttpParams(search);

    return this.get<R[]>('', {params}).pipe(
      map((list: R[]): M[] => this.convertListToModel(list))
    );
  }

  public insert(resource: R): Observable<M> {
    return this.post<R>('', resource).pipe(
      map((res: R): M => this.convertResourceToModel(res))
    );
  }

  public update(resource: R): Observable<M> {
    return this.put<R>('', resource).pipe(
      map((res: R): M => this.convertResourceToModel(res))
    );
  }

  public deleteByIds(ids: any[]): Observable<number> {
    const params = [];
    for (const id of ids) {
      params.push(`ids=${id}`);
    }

    const queryString = params.join('&');
    return this.http.delete<any>(this.buildEndpointUrl(`?${queryString}`));
  }

  protected buildGetParams(params: Map<string, any>): string {
    const parts: string[] = [];
    for (let [key, value] of params) {
      parts.push(`${key}=${value}`);
    }

    return parts.join('&');
  }

  public detail(id: any): Observable<M> {
    return this.get<R>(`${id}`).pipe(
      map((res: R): M => this.convertResourceToModel(res))
    );
  }

  public delete(id: any): Observable<boolean> {
    return this.http.delete<boolean>(this.buildEndpointUrl(`${id}`));
  }

  public abstract convertResourceToModel(resource: R): M;

  public abstract convertModelToResource(model: M): R;

  public convertListToModel(list: R[]): M[] {
    return list.map((resource: R): M => this.convertResourceToModel(resource));
  }

  public convertListToResource(list: M[]): R[] {
    return list.map((model: M): R => this.convertModelToResource(model));
  }

  protected buildEndpointUrl(url: string): string {
    if (StringUtils.isEmpty(this.endpoint)) {
      return `${this.baseUrl}/${url}`;
    }

    return `${this.baseUrl}/${this.endpoint}/${url}`;
  }
}
