import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {TraceabilityService} from '../../services/traceability.service';
import {Traceability} from '../../models/traceability.model';
import {TraceabilityProcessEnum} from '../../enums/traceability-process.enum';
import {AuthenticationService, CryptoService} from '@pwc/security';
import {RedirectService} from '@pwc/common';
import {environment} from '../../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {tap} from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  otpForm: FormGroup;
  loading = false;
  submitted = false;
  otpRequired = false;
  returnUrl: string;
  error = '';
  readonly ecobonusFlag = environment.ecobonusFlag;
  readonly bandiIncentiviFlag = environment.bandiIncentiviFlag;
  readonly baseHref = environment.baseHref;

  readonly urlEcobonus = environment.urlEcobonus;
  readonly faq: boolean = environment.features.faq;

  private otpToken;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private traceService: TraceabilityService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private cryptoService: CryptoService,
    private redirectService: RedirectService
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.otpForm = this.formBuilder.group({
      code: ['', Validators.required]
    });

    // reset login status
    // this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (params.get('one-time-token')) {
        this.authenticationService.setToken(null, params.get('one-time-token'));
        this.authenticationService.refreshToken()
          .pipe(
            tap(() => {
              this.redirectService.navigate(this.returnUrl);
            })
          );
        return;
      }

      if (params.get('token') || params.get('refresh_token')) {
        this.authenticationService.setToken(params.get('token'), params.get('refresh_token'));
        this.redirectService.navigate(this.returnUrl);
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }

  get loginBackground(): { [p: string]: string } {
    return {background: `url(${this.baseHref}/assets/images/login-bg.jpg) center bottom`};
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.error = '';
    this.loading = true;
    // const password = this.cryptoService.encryptToString(this.loginForm.get('password').value);
    const password = this.loginForm.get('password').value;
    this.authenticationService.login(this.loginForm.get('username').value, password)
      .pipe(first())
      .subscribe(
        data => {
          this.afterLogin(data);
        },
        error => {
          if (error.status === 403 && error.error != null && error.error.error === 'otp_required') {
            this.otpToken = error.error.otp_token;
            this.submitted = false;
            this.otpRequired = true;
            this.loading = false;
            return;
          } else if (error.status === 409 && error.error != null && error.error.error === 'concurrent_login_detected') {
            this.submitted = false;
            this.error = 'È stato rilevato una sessione attiva su un altro dispositivo. Tutte le sessioni sono state terminate. Reinserire le credenziali per accedere.';
            this.loading = false;
            return;
          }

          // console.log(error);
          this.error = 'Credenziali errate';
          this.loading = false;
        });
  }

  onSubmitOtp() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    this.error = '';
    this.loading = true;

    this.authenticationService.loginOtp(this.otpToken, this.otpForm.get('code').value)
      .pipe(first())
      .subscribe(
        data => {
          this.afterLogin(data);
        },
        error => {
          console.error(error);

          if (error.status === 409 && error.error != null && error.error.error === 'concurrent_login_detected') {
            this.submitted = false;
            this.error = 'È stato rilevato una sessione attiva su un altro dispositivo. Tutte le sessioni sono state terminate. Reinserire le credenziali per accedere.';
            this.loading = false;
            return;
          }

          this.error = 'Codice errato';
          this.loading = false;
        });
  }

  private afterLogin(data): void {
    const trace = new Traceability();
    trace.activity = 'Log-in';
    trace.process = TraceabilityProcessEnum.USER_ACCESS;
    this.traceService.addTraceability(trace).subscribe(res => {
      this.redirect(data);
    }, error => {
      this.redirect(data);
    });
  }

  private redirect(data): void {
    if (this.redirectService.external(this.returnUrl)) {
      let url = this.returnUrl;
      if (!this.bandiIncentiviFlag) {
        url = `${url}?token=${data.access_token}&refresh_token=${data.refresh_token}`;
      }
      window.location.href = url;
    } else {
      this.redirectService.navigate(this.returnUrl);
    }
  }
}
