import {Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Observable} from 'rxjs';
import {Company} from '../../../models/company.model';
import {NgbdSortableHeader, SortEvent} from '../../../directives/sortable.directive';
import {CompanyService} from '../../../services/company.service';
import {isNullOrUndefined} from 'util';
import {LocalTranslateEnum} from '../../../enums/local-translate.enum';
import {TranslateService} from '@ngx-translate/core';
import {mapTranslateMessages, tranlastionKeys} from '../../../helpers/generic-utils';
import {Authorities} from '../../../enums/security/authorities.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
  providers: [CompanyService]
})
export class CompaniesListComponent implements OnInit {
  companiesList$: Observable<Company[]>;
  filteredList$: Observable<Company[]>;
  total$: Observable<number>;
  total: number;
  selectAll: boolean;
  selectedList: Company[] = [];
  filteredList: Company[] = [];
  selectedCount: number = 0;

  requiredAuthorities = {
    company: {
      read: Authorities.ROLE_COMPANY_READ,
      create: Authorities.ROLE_COMPANY_CREATE,
      update: Authorities.ROLE_COMPANY_UPDATE,
      delete: Authorities.ROLE_COMPANY_DELETE,
    },
    parentCompany: {
      read: Authorities.ROLE_PARENT_COMPANY_READ,
      create: Authorities.ROLE_PARENT_COMPANY_CREATE,
      update: Authorities.ROLE_PARENT_COMPANY_UPDATE,
      delete: Authorities.ROLE_PARENT_COMPANY_DELETE,
    }
  };

  readonly baseHref = environment.baseHref;

  @Output() showLoading = new EventEmitter<boolean>();
  @Output() openDetail = new EventEmitter<object>();
  @ViewChild('alertModalList', {static: true}) alertModal: any;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  messages: any;

  constructor(public service: CompanyService,
              private translateService: TranslateService) {
    this.companiesList$ = service.companiesList$;
    this.filteredList$ = service.filteredList$;
    this.total$ = service.total$;
    this.initTranslations();
    this.translateService.onLangChange.subscribe(event => {
      // console.log('onLangChange', event);
      this.loadDataByLang();
      this.initTranslations();
    });
  }

  initTranslations() {
    if (isNullOrUndefined(this.translateService)) {
      return;
    }

    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
      // console.log('messages', this.messages);
      this.loadDataByLang();
    });
  }

  loadDataByLang() {
    // this.service.isAdmin.subscribe( auth => {
    //
    // })
    this.service.loadService();
  }

  ngOnInit() {
    this.selectAll = false;


    this.service.loading$.subscribe(show => {
      this.onShowLoading(show);
    });
    this.total$.subscribe(total => {
      this.total = total;
    });
    this.companiesList$.subscribe(companies => {
      // console.debug('companies', companies);
    });
    this.filteredList$.subscribe(list => {
      // console.log('filteredList-', list);
      this.filteredList = list;
    });
  }

  onOpenDetail(id: number, permission: string) {
    this.openDetail.emit({id, permission});
  }

  onShowLoading(show: boolean) {
    this.showLoading.emit(show);
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  selectVisibleRows(selected: boolean) {
    this.companiesList$.forEach(list => {
      // console.log('list', list);
      list.forEach(i => {
        i.selected = selected;
      });
      if (selected) {
        this.selectedList = list.filter(e => e.selected);
      } else {
        this.selectedList = [];
      }
      this.selectedCount = this.selectedList.length;
    });
    this.selectAll = selected;
  }

  selectAllRows() {
    this.companiesList$.forEach(list => {
      // console.log('list', list);
      list.forEach(i => {
        i.selected = true;
      });
      // this.selectedList = list.filter(e => e.selected);
      this.selectedList = this.service.companies;
      this.selectedCount = this.selectedList.length;
    });
    this.selectAll = true;
  }

  selectRows(id: number, selected: boolean) {
    // console.log('selected', selected);
    this.companiesList$.forEach(list => {
      // console.log('list', list);
      list.map(i => {
        i.selected = (i.id === id) ? selected : i.selected;
      });
      this.selectedList = list.filter(e => e.selected);
      this.selectedCount = this.selectedList.length;
    });
    this.selectAll = false;
  }

  pageChange() {
    this.selectAll = false;
    this.selectVisibleRows(false);
  }

  resetFilters() {
    this.service.searchID = '';
    this.service.searchVatNumber = '';
    this.service.searchName = '';
    this.service.searchTerm = '';
  }

  deleteCompany(id: any) {
    if (confirm(this.messages.deleteConfirm)) {
      this.service.deleteCompany(id).subscribe(res => {
        this.openAlert(0, 'success', this.messages.savingSuccess);
      }, (err) => {
        // console.log(err);
        this.openAlert(0, 'error', this.messages.errorSaving);
      });
    }
  }

  deleteMassiveCompanies() {
    if (confirm(this.messages.deleteSelectedConfirm)) {
      this.service.deleteAllCompanies(this.selectedList.map(u => u.id)).subscribe(res => {
        this.openAlert(0, 'success', this.messages.deleteSuccess);
      }, (err) => {
        // console.log(err);
        this.openAlert(0, 'error', this.messages.errorGeneric);
      });
    }
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  callbackApprovation(type: string) {
    if (type === 'success') {
      this.service.loadService();
    }
  }

  export(selected: boolean = false) {
    let fullList = [];
    if (selected) {
      fullList = this.selectedList;
    } else {
      fullList = this.filteredList;
    }
    // console.log('export list', fullList);
    const list = fullList.map(i => {
      return {
        id: i.id,
        vatNumber: i.vatNumber,
        companyName: i.name
      };
    });
    this.service.exportAsExcel(list);
  }
}
