<div class="row reset-password-page">
  <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 ml-auto mr-auto login-col">
    <section class="login-box">
      <div class="row">
        <div class="col align-self-center">
          <div class="card login-card">
            <div class="card-title">
              <div class="col lang-box" *ngIf="false">
                <select class="custom-select" id="gender2" style="float: right">
                  <option selected value="it">IT</option>
                  <option value="en">EN</option>
                </select>
              </div>
            </div>
            <div class="card-body">
              <div *ngIf="ecobonusFlag">
                <div class="col welcome-box mt-4 lead text-center">
                  Piattaforma
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/login-ecobonus.png'"/>
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-login.png'"/>
                </div>
              </div>
              <div *ngIf="bandiIncentiviFlag">
                <div class="col welcome-box mt-4 lead text-center">
                  Piattaforma
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-bandi-e-incentivi.png'"/>
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-login.png'"/>
                </div>
              </div>
              <div *ngIf="!ecobonusFlag && !bandiIncentiviFlag" class="col image-box mt-2">
                <img class="image-login" [src]="baseHref + '/assets/images/digital-suite-tls.png'"/>
              </div>
              <div class="col welcome-box">
                <h3>Reset password</h3>
                <span *ngIf="false">Inserisci la nuova password.</span>
                <hr>
              </div>
              <div class="col form-box">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group" *ngIf="!showSuccess">
                    <label for="password">
                      <span>Password</span>
                      <pwc-icon name="info" [ngbTooltip]="pwdRules"></pwc-icon>
                    </label>
                    <input type="password" formControlName="password" class="form-control"
                           [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password è obbligatoria</div>
                      <div *ngIf="f.password.errors.minlength">La password deve contenere almeno 8 caratteri</div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!showSuccess">
                    <label for="confirmPassword">
                      <span>Conferma password</span>
                      <pwc-icon name="info" [ngbTooltip]="pwdRules"></pwc-icon>
                    </label>
                    <input type="password" maxlength="255" formControlName="confirmPassword" class="form-control"
                           [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"/>
                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                      <div *ngIf="f.confirmPassword.errors.required" translate>
                        Conferma password obbligatoria
                      </div>
                      <div *ngIf="f.confirmPassword.errors.mustMatch" translate>
                        Le password devono coincidere
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!showSuccess">
                    <br>
                    <button [disabled]="loading" class="btn btn-primary">Cambia password</button>
                    <img *ngIf="loading" class="pl-2"
                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                  </div>
                  <div *ngIf="error && !showSuccess" class="alert alert-danger">{{error}}</div>
                  <div *ngIf="showSuccess" class="text-center">La password è stata aggiornata</div>
                </form>
              </div>
              <div class="col welcome-box">
                <hr>
                <span>Torna alla pagina di <a href="/login">Login</a></span>

              </div>
              <div class="col welcome-box">
                <br>
                <img alt="PwC TLS Avvocati e commercialisti" class="logo-footer ml-auto"
                     [src]="baseHref + '/assets/images/logo-footer.svg'">
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</div>

<ng-template #pwdRules>
  <p class="mb-0 text-left" style="color:#fff;">La password deve rispettare i seguenti criteri:</p>
  <ul class="pl-2 ml-0 text-left">
    <li>lunghezza minima di 8 caratteri</li>
    <li>deve contenere lettere minuscole e maiuscole, numeri e caratteri speciali</li>
    <li>non deve contenere nome o cognome dell'utente, username o nomi scontati (admin, password, 123, etc)</li>
    <li>deve essere diversa dalle ultime 10 password utilizzate</li>
  </ul>
</ng-template>
