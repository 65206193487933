<div class="page-container">
  <h3 translate>menu.account.change-password.label</h3>
  <div class="lds-dual-ring" *ngIf="loading"></div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" translate>menu.account.label</li>
      <li class="breadcrumb-item active" aria-current="page" translate>menu.account.change-password.label</li>
    </ol>
  </nav>

  <form [formGroup]="form" class="form-horizontal">

    <div class="card my-4">
      <div class="card-body">

        <div class="form-group form-input-group form-inline row my-4">
          <label for="password" class="form-input-label col-md-2" translate>
            Vecchia password
          </label>
          <div class="col-md-6">
            <input type="password" class="form-control form-input input-with-border" formControlName="oldPassword"
                   id="oldPassword" name="oldPassword"
                   [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" required>
            <div
              *ngIf="submitted && f.oldPassword.errors !== null"
              class="invalid-feedback">
              <div *ngIf="f.oldPassword.errors.required" translate>generic.message.validation.mandatory-error</div>
            </div>
          </div>
        </div>

        <div class="form-group form-input-group form-inline row my-4">
          <label for="password" class="form-input-label col-md-2" translate>
            generic.field.password
          </label>
          <div class="col-md-6">
            <input type="password" class="form-control form-input input-with-border" formControlName="password"
                   id="password" name="password"
                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required>
            <div *ngIf="f.password.value !== ''" class="my-2">
              <pwc-password-meter [password]="f.password.value"></pwc-password-meter>
            </div>
            <div
              *ngIf="submitted && f.password.errors !== null"
              class="invalid-feedback">
              <div *ngIf="f.password.errors.required" translate>generic.message.validation.mandatory-error</div>
              <div *ngIf="f.password.errors.minlength" translate>generic.message.validation.password-min-length
              </div>
            </div>
          </div>
          <div class="info-wrapper">
            <pwc-icon name="info" [ngbTooltip]="pwdRules"></pwc-icon>
          </div>
        </div>

        <div class="form-group form-input-group form-inline row my-4">
          <label for="confirmPassword" class="form-input-label col-md-2" translate>
            generic.field.password-confirm
          </label>
          <div class="col-md-6">
            <input type="password" class="form-control form-input input-with-border" formControlName="confirmPassword"
                   [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                   id="confirmPassword" name="confirmPassword" required>
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
              <div *ngIf="f.confirmPassword.errors.mustMatch" translate>
                generic.message.validation.password-confirm-check
              </div>
            </div>
          </div>
          <div class="info-wrapper">
            <pwc-icon name="info" [ngbTooltip]="pwdRules"></pwc-icon>
          </div>
        </div>

      </div>
    </div>

    <section class="my-3">
      <div class="row">
        <div class="col">
          <div class="d-flex flex-row p-1">
            <button class="btn btn-primary" (click)="save()"
                    [disabled]="loading">{{ 'generic.action.save' | translate }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <app-alert #alertModal [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
  </form>
</div>

<ng-template #pwdRules>
  <p class="mb-0 text-left" style="color:#fff;">La password deve rispettare i seguenti criteri:</p>
  <ul class="pl-2 ml-0 text-left">
    <li>lunghezza minima di 8 caratteri</li>
    <li>deve contenere lettere minuscole e maiuscole, numeri e caratteri speciali</li>
    <li>non deve contenere nome o cognome dell'utente, username o nomi scontati (admin, password, 123, etc)</li>
    <li>deve essere diversa dalle ultime 10 password utilizzate</li>
  </ul>
</ng-template>
