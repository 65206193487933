import {baseEnvironment} from './base.environment';

export const environment = {
    ...baseEnvironment,
    production: true,
    cookieDomain: 'pwc-tls.it',
    cookiePath: '/',
    secureFlag: true,
    apiUrl: 'https://dev1.devds-backend.digitalsuite.pwc-tls.it',
    meUrl: 'https://dev1.devds.digitalsuite.pwc-tls.it/',
    url2086: 'https://digitallab-2086-dev.pwc-tls.it',
    urlDocumentale: 'https://dms.devds.digitalsuite.pwc-tls.it',
    urlEcobonus: '#',
    urlDiagnostico: 'https://diagnosticocg-dev.digitalsuite.pwc-tls.it',
    idStringLength: 3,
    encryption: {
      phrase: 'TCkOI9NnOVmVRBKRnEPUWBeMp9F3GlsU',
      salt: '6bae6f515e6099b3b104eaaf9fe7861f',
      iv: '647a94ccb5fad019165cf9f7a81d9dea'
    },
    ecobonusFlag: false,
    urlCorporateGovernance: 'https://gc-devds.digitalsuite.pwc-tls.it',
    urlDpo: 'https://dpo-dev.digitalsuite.pwc-tls.it',
  };
