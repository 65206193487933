import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-widget-communico',
  templateUrl: './widget-communico.component.html',
  styleUrls: ['./widget-communico.component.scss']
})
export class WidgetCommunicoComponent implements OnInit {

  readonly baseHref = environment.baseHref;

  constructor() { }

  ngOnInit() {
  }

}
